import { defineStore } from "pinia";
import axios from 'axios';
import { reactive, toRaw, ref } from "vue";

if (import.meta.env.MODE == "development") {
    axios.defaults.baseURL = "http://localhost:3000";
}

export const dataStore = defineStore('data', () => {
    const output = reactive({
        result: [],
    });

    const inputSimple = reactive({
        initial_balance: undefined,
        expected_interest_rate: undefined,
        interest_rate_compound_interval: 'Yearly',
        deposits: undefined,
        deposit_frequency: 'Monthly',
        deposit_stop_after_years: undefined,
        withdrawals: undefined,
        withdrawal_frequency: 'Monthly',
        inflation_rate: undefined,
        deposits_incremental_rate: undefined,
        withdrawal_incremental_rate: undefined,
    });

    const inputAdvanced = reactive({
        initial_balance: undefined,
        expected_interest_rate: undefined,
        deposit_schedules: [],
        withdrawal_schedules: [],
        goals: [],
    });

    function compute(isSimpleInput) {
        if (isSimpleInput) {
            const input = cleanInput(inputSimple);
            axios.post('/input', input)
                .then(function (response) {
                    output.result = JSON.parse(response.data);
                })
        } else {
            const input = cleanInput(this.inputAdvanced);
            axios.post('/input_advanced', input)
                .then(function (response) {
                    output.result = JSON.parse(response.data);
                })
        }
    }

    function createScheduleObject() {
        return {
            amount: "",
            frequency: "Yearly",
            change_percentage: "",
            year_range: [0, 100],
        };
    }

    function addDepositSchedule() {
        this.inputAdvanced.deposit_schedules.push(createScheduleObject());
    }

    function addWithdrawalSchedule() {
        this.inputAdvanced.withdrawal_schedules.push(createScheduleObject());
    }

    function addGoal() {
        this.inputAdvanced.goals.push({
            name: "",
            amount: "",
            inflation_rate: 0.0,
            year: 0,
        });
    }

    function deleteDepositSchedule(index) {
        this.inputAdvanced.deposit_schedules.splice(index, 1);
    }

    function deleteWithdrawalSchedule(index) {
        this.inputAdvanced.withdrawal_schedules.splice(index, 1);
    }

    function deleteGoal(index) {
        this.inputAdvanced.goals.splice(index, 1);
    }

    function storeDataLocally() {
        // localStorage.setItem("inputSimple", JSON.stringify(inputSimple));
        localStorage.setItem("inputAdvanced", JSON.stringify(this.inputAdvanced));
    }

    function retrieveDataLocally() {
        // inputSimple = JSON.parse(localStorage.getItem("inputSimple"));
        let data = JSON.parse(localStorage.getItem("inputAdvanced"));
        if(data) {
            this.inputAdvanced = data;
        }
    }

    function clearData() {
        // this.inputSimple = {
        //     initial_balance: undefined,
        //     expected_interest_rate: undefined,
        //     interest_rate_compound_interval: 'Yearly',
        //     deposits: undefined,
        //     deposit_frequency: 'Monthly',
        //     deposit_stop_after_years: undefined,
        //     withdrawals: undefined,
        //     withdrawal_frequency: 'Monthly',
        //     inflation_rate: undefined,
        //     deposits_incremental_rate: undefined,
        //     withdrawal_incremental_rate: undefined,
        // };
        this.inputAdvanced = {
            initial_balance: undefined,
            expected_interest_rate: undefined,
            deposit_schedules: [],
            withdrawal_schedules: [],
            goals: [],
        };
    }

    return {
        inputSimple,
        inputAdvanced,
        output,
        compute,
        addDepositSchedule,
        addWithdrawalSchedule,
        deleteDepositSchedule,
        deleteWithdrawalSchedule,
        deleteGoal,
        addGoal,
        storeDataLocally,
        retrieveDataLocally,
        clearData,
    }
})

function cleanNumber(num) {
    return parseInt(num.replace(/,/g, ''));
}

function cleanInput(data) {
    const input = {};
    for (const key in toRaw(data)) {
        if (toRaw(data)[key]) {
            if (key == "initial_balance" || key == "deposits" || key == "withdrawals") {
                input[key] = cleanNumber(toRaw(data)[key]);
            } else if (key == "deposit_schedules" || key == "withdrawal_schedules") {
                input[key] = [];
                for (const i in toRaw(data)[key]) {
                    const amount = cleanNumber(toRaw(data)[key][i].amount);
                    input[key].push({
                        amount,
                        frequency: data[key][i].frequency,
                        change_percentage: data[key][i].change_percentage,
                        year_range: data[key][i].year_range
                    });
                }
            } else if (key == "goals") {
                input[key] = [];
                for (const i in toRaw(data)[key]) {
                    const amount = cleanNumber(toRaw(data)[key][i].amount);
                    input[key].push({
                        name: data[key][i].name,
                        amount,
                        inflation_rate: data[key][i].inflation_rate,
                        year: data[key][i].year,
                    });
                }
            }
            else {
                input[key] = toRaw(data)[key];
            }
        }
        else {
            input[key] = 0;
        }
    }
    return input;
}