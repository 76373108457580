import './assets/main.css'

import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'

export function formatNumber(num) {
    return Intl.NumberFormat("en-IN").format(parseInt(num));
}

createApp(App).use(createPinia()).mount('#app')
