<script setup>
import Input from './components/Input.vue';
import Output from './components/Output.vue';
import InputAdvanced from './components/InputAdvanced.vue';
import { ref } from 'vue';

const prodMode = import.meta.env.MODE === 'production';
console.log(import.meta.env.MODE);
const currentTab = ref('Advanced');
</script>

<template>
  <header>
  </header>

  <main>
    <div class="content-center p-1">
      <p class="text-xl font-bold text-center">🔥Retirement🔥 Savings Calculator</p>
      <p class="text-sm text-center">Made with <a href="https://www.rust-lang.org/">🦀</a> by <a class="link-info"
          href="https://twitter.com/shantanugoel">Shaan</a>
      </p>
    </div>
    <div class="flex justify-center p-1 tabs tabs-boxed">
      <a @click="currentTab = 'Simple'" class="tab" :class="{ 'tab tab-active': currentTab === 'Simple' }">
        Simple Mode
      </a>
      <div class="indicator">
        <span class="indicator-item badge badge-accent">Beta</span>
        <button @click="currentTab = 'Advanced'" class="tab" :class="{ 'tab tab-active': currentTab === 'Advanced' }">
          Advanced Mode
        </button>
      </div>
    </div>
    <Input v-if="currentTab === 'Simple'" />
    <InputAdvanced v-else />
    <Output />
  </main>
</template>

<style scoped></style>
