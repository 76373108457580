// Follows the example of https://blog.excalidraw.com/end-to-end-encryption/
const subtle = window.crypto.subtle;
export async function generateKey() {
    const algorithm = {
        name: 'AES-GCM',
        length: 256
    };

    return await subtle.generateKey(algorithm, true, ['encrypt', 'decrypt']);
}

export async function encrypt(key, data) {
    return await subtle.encrypt(
        {
            name: 'AES-GCM',
            iv: new Uint8Array(12), // Can be left as 0 because we don't reuse it
            tagLength: 128
        },
        key,
        new TextEncoder().encode(data)
    );
}

export async function sha256(data) {
    return subtle.digest("SHA-256", new TextEncoder().encode(data));
}

export async function getKeyString(key) {
    return (await subtle.exportKey("jwk", key)).k;
}

export async function retreiveKeyfromString(keyString) {
    return subtle.importKey(
        "jwk",
        {
            kty: "oct",
            k: keyString,
        },
        {
            name: "AES-GCM",
            length: 256
        },
        true,
        ["decrypt", "encrypt"]
    );
}
export async function decrypt(keyString, encrypted) {
    const cryptoKey = await retreiveKeyfromString(keyString);

    const decrypted = await subtle.decrypt(
            {
                name: 'AES-GCM',
                iv: new Uint8Array(12),
            },
            cryptoKey,
            new Uint8Array(encrypted).buffer
        );

    return new TextDecoder().decode(decrypted);
}