<script setup>
import { dataStore } from '../stores/data';
import { storeToRefs } from 'pinia';
import { formatNumber } from '../main.js';
import Charts from './Charts.vue';
import Exporter from './Exporter.vue';

const store = dataStore();
</script>

<template>
    <div v-show="store.output.result.length != 0" class="overflow-x-auto flex flex-col justify-center items-center">
        <div class="py-1">
            <p class=" font-bold">
                <span>Your savings will probably last you </span>
                <span v-if="store.output.result.length < 100">around </span>
                <span v-else>more than </span>
                <span class="text-error text-xl">{{ store.output.result.length }} </span>
                <span> years.</span>
            </p>
        </div>
        <div class="py-1">
            <Exporter />
        </div>
    </div>
    <div v-show="store.output.result.length != 0" class="overflow-x-auto flex justify-center items-center">
        <Charts />
    </div>
    <div class="overflow-x-auto grid grid-cols-4">
        <table class="table-xs table-zebra pin-cols md:col-start-2 md:col-span-2 sm:col-span-4 table-auto">
            <thead v-if="store.output.result.length != 0">
                <tr class="text-right">
                    <th>Year</th>
                    <th>Interest</th>
                    <th>Deposits</th>
                    <th>Withdrawals</th>
                    <th>Net Change</th>
                    <th>Balance</th>
                </tr>
            </thead>
            <tbody class="text-right">
                <tr v-for="row in store.output.result" class="hover">
                    <td>{{ row.year }}</td>
                    <td>{{ formatNumber(row.interest) }}</td>
                    <td>{{ formatNumber(row.deposit) }}</td>
                    <td>{{ formatNumber(row.withdrawal) }}</td>
                    <td>{{ formatNumber(row.net_change) }}</td>
                    <td>{{ formatNumber(row.balance) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

