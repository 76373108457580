<script setup>
import { nextTick } from 'vue';

const props = defineProps({
    modelValue: String,
    modelModifiers: { default: () => ({}) }
});

const emit = defineEmits(['update:modelValue']);

const getUserLocale = () => {
    if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
    }
    return navigator.language;
};

function emitValue(e) {
    let value = e.target.value;
    let caret = e.target.selectionStart;
    if (props.modelModifiers.formattedInputNumber) {
        let new_value = parseInt(value.replace(/,/g, ''));
        if (new_value != 0) {
            if (isNaN(new_value)) {
                new_value = "";
            } else {
                new_value = Intl.NumberFormat("en-IN").format(new_value);
                emit('update:modelValue', new_value);
                if (new_value != value) {
                    if (new_value.length > value.length) {
                        caret = new_value.length - value.length + caret;
                    }
                    nextTick(() => { e.target.selectionEnd = e.target.selectionStart = caret; });
                }
            }
        }
    }
}
</script>

<template>
    <input type="text" :value="modelValue" @keyup="emitValue" />
</template>