<script setup>
import { dataStore } from '../stores/data';
import { watch, ref } from 'vue';
import * as XLSX from 'xlsx';

const store = dataStore();
const exportData = ref([]);

// Watch for changes in store.output.result
watch(() => store.output.result, (newData) => {
    if (newData.length) {
        exportData.value = newData.map((row) => ({
            Year: row.year,
            interest: row.interest,
            Deposit: row.deposit,
            Withdrawal: row.withdrawal,
            NetChange: row.net_change,
            Balance: row.balance,
        }));
    }
});

const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(exportData.value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcelFile(excelBuffer, 'data.xlsx');
};

const saveAsExcelFile = (buffer, fileName) => {
    const data = new Blob([buffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
</script>

<template>
    <button type="button" @click="exportToExcel" class="btn btn-outline plausible-event-name=Export">Export Data to xlsx</button>
</template>