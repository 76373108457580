<script setup>
import { dataStore } from '../stores/data';
import Numbers from './Numbers.vue';
const store = dataStore();
</script>

<template>
    <form @submit.prevent="store.compute(true)">
        <div class="grid grid-rows md:grid-cols-4 space-y-1">
            <label class="label text-base label-text md:col-start-2">Current Total Savings </label>
            <numbers placeholder="1,00,00,000" v-model.formattedInputNumber="store.inputSimple.initial_balance"
                class="input input-bordered" />
        </div>
        <div class="grid grid-rows md:grid-cols-4 space-y-1">
            <label class="label text-base label-text md:col-start-2">Expected post tax annual interest % </label>
            <input type="number" step="0.01" placeholder="8.5" min=0
                v-model.number="store.inputSimple.expected_interest_rate" class="input input-bordered" />
        </div>
        <div class="grid grid-rows md:grid-cols-4 space-y-1">
            <label class="label md:col-start-2 text-base label-text">Monthly Deposits </label>
            <numbers placeholder="1,00,000" v-model.formattedInputNumber="store.inputSimple.deposits"
                class="w-full input input-bordered" />
        </div>
        <div class="grid grid-rows md:grid-cols-4 space-y-1">
            <label class="label md:col-start-2 text-base label-text">Increase Deposits every year by % </label>
            <input type="number" step="0.01" placeholder="5.5" min=0
                v-model.number="store.inputSimple.deposits_incremental_rate" class="w-full input input-bordered" />
        </div>
        <div class="grid grid-rows md:grid-cols-4 space-y-1">
            <label class="label text-base label-text md:col-start-2">Stop new deposits after years </label>
            <input type="number" placeholder="10" min=0 v-model.number="store.inputSimple.deposit_stop_after_years"
                class="input input-bordered" />
        </div>
        <div class="grid grid-rows md:grid-cols-4 space-y-1">
            <label class="label md:col-start-2 text-base label-text">Monthly Withdrawals </label>
            <numbers placeholder="1,50,000" v-model.formattedInputNumber="store.inputSimple.withdrawals"
                class="w-full input input-bordered" />
        </div>
        <div class="grid grid-rows md:grid-cols-4 space-y-1">
            <label class="label md:col-start-2 text-base label-text">Increase withdrawals every year by % </label>
            <input type="number" step="0.01" placeholder="7.0" min=0
                v-model.number="store.inputSimple.withdrawal_incremental_rate" class="w-full input input-bordered" />
        </div>
        <div class="grid grid-cols-2 md:grid-cols-4 m-2">
            <button type="submit" class="btn btn-primary btn-block md:col-start-2 col-span-2 plausible-event-name=Calculate+Simple">
                Calculate </button>
        </div>
    </form>
</template>
