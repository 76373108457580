<script setup>
import { dataStore } from '../stores/data';
import Sharing from './Sharing.vue';
import Numbers from './Numbers.vue';
import Slider from '@vueform/slider';
const store = dataStore();
</script>

<style>
@import '@vueform/slider/themes/tailwind.scss';
</style>

<template>
    <form @submit.prevent="store.compute(false)">
        <div class="flex justify-center flex-wrap">
            <Sharing />
            <button type="button" @click="store.clearData" class="btn btn-outline m-1">
                Clear Input Data</button>
            <button type="button" @click="store.storeDataLocally" class="btn btn-outline m-1"
                title="Save input data to your browser locally">
                Save Input Data</button>
            <button type="button" @click="store.retrieveDataLocally" class="btn btn-outline m-1"
                title="Retrieve previous saved input data from your browser if it exists">
                Retrieve Saved Input Data</button>
        </div>
        <div class="collapse collapse-arrow bg-base-200 my-1">
            <input type="checkbox" name="accordion" checked="checked" title="Savings data and growth expectations" />
            <div class="collapse-title text-xl font-medium">
                Current Savings
            </div>
            <div class="collapse-content">
                <div class="grid grid-rows md:grid-cols-4 space-y-1">
                    <label class="label text-base label-text md:col-start-2">Amount
                        <div class="tooltip tooltip-left md:tooltip-top"
                            data-tip="Total savings that you have today across all your investments. Exclude the house in which you live.">
                            🛈</div>
                    </label>
                    <numbers placeholder="Ex: 1,00,00,000"
                        v-model.formattedInputNumber="store.inputAdvanced.initial_balance" class="input input-bordered"
                        required />
                </div>
                <div class="grid grid-rows md:grid-cols-4 space-y-1">
                    <label class="label text-base label-text md:col-start-2">Expected post tax annual interest %
                        <div class="tooltip tooltip-left md:tooltip-top"
                            data-tip="What is the annual return you expect from your investments after adjusting for any taxes?">
                            🛈</div>
                    </label>
                    <input type="number" step="0.01" placeholder="Ex: 8.5" min="0"
                        v-model.number="store.inputAdvanced.expected_interest_rate" class="input input-bordered"
                        required />
                </div>
            </div>
        </div>
        <div class="collapse collapse-arrow bg-base-200 my-1">
            <input type="checkbox" name="accordion"
                title="Future deposits expected either from remaining service period or passive income etc" />
            <div class="collapse-title text-xl font-medium">
                Deposit Schedules
            </div>
            <div class="collapse-content">
                <div v-for="(deposit, index) in store.inputAdvanced.deposit_schedules" :key="index">
                    <div class="grid grid-rows md:grid-cols-4 space-y-1">
                        <label class="label text-base label-text md:col-start-2">Deposit Schedule {{ index + 1 }}
                        </label>
                        <a type="button" @click="store.deleteDepositSchedule(index)" class="btn btn-square">🗑️</a>
                        <label class="label text-base label-text md:col-start-2">Amount
                            <div class="tooltip tooltip-left md:tooltip-top"
                                data-tip="Recurring amount that you intend to save"> 🛈</div>
                        </label>
                        <numbers placeholder="Ex: 1,00,000" v-model.formattedInputNumber="deposit.amount"
                            class="input input-bordered" required />
                    </div>
                    <div class="grid grid-rows md:grid-cols-4 space-y-1">
                        <label class="label text-base label-text md:col-start-2">Frequency
                            <div class="tooltip tooltip-left md:tooltip-top"
                                data-tip="Will this amount be saved every month or total of every year?">
                                🛈</div>
                        </label>
                        <div>
                            <label>
                                <input type="radio" value="Yearly" v-model="deposit.frequency" />
                                Yearly
                            </label>
                            <label>
                                <input type="radio" value="Monthly" v-model="deposit.frequency" />
                                Monthly
                            </label>
                        </div>
                    </div>
                    <div class="grid grid-rows md:grid-cols-4 space-y-1">
                        <label class="label text-base label-text md:col-start-2">Change the amount every year by %
                            <div class="tooltip tooltip-left md:tooltip-top"
                                data-tip="Enter a positive rate value to increase deposit every year by that %, or a negative rate value to decrease it by that %">
                                🛈</div>
                        </label>
                        <input type="number" step="0.01" placeholder="Ex: 8.5" min="0"
                            v-model.number="deposit.change_percentage" class="input input-bordered" required />
                    </div>
                    <div class="grid grid-rows md:grid-cols-4 space-y-1 range-slider">
                        <label class="label text-base label-text md:col-start-2">Year Range (Year 0 = This year)
                            <div class="tooltip tooltip-left md:tooltip-top"
                                data-tip="When do you expect this saving to start/stop?">
                                🛈</div>
                        </label>
                        <Slider v-model="deposit.year_range" showTooltip="always" tooltipPosition="bottom"
                            class="max-sm:px-2" />
                    </div>
                </div>
                <div class="grid grid-rows md:grid-cols-4 mt-4 max-sm:mt-11">
                    <button type="button" @click="store.addDepositSchedule" class="btn btn-outline md:col-start-2">Add
                        Deposit
                        Schedule</button>
                </div>
            </div>
        </div>
        <div class="collapse collapse-arrow bg-base-200 my-1">
            <input type="checkbox" name="accordion"
                title="Recurring withdrawals expected for meeting general expenses" />
            <div class="collapse-title text-xl font-medium">
                Withdrawal Schedules
            </div>
            <div class="collapse-content">
                <div v-for="(withdrawal, index) in store.inputAdvanced.withdrawal_schedules" :key="index">
                    <div class="grid grid-rows md:grid-cols-4 space-y-1">
                        <label class="label text-base label-text md:col-start-2">Withdrawal Schedule {{ index + 1 }}
                        </label>
                        <a type="button" @click="store.deleteWithdrawalSchedule(index)" class="btn btn-square">🗑️</a>
                        <label class="label text-base label-text md:col-start-2">Amount
                            <div class="tooltip tooltip-left md:tooltip-top"
                                data-tip="Recurring amount that you intend to withdraw to meet your expenses?">
                                🛈</div>
                        </label>
                        <numbers placeholder="Ex: 1,00,000" v-model.formattedInputNumber="withdrawal.amount"
                            class="input input-bordered" required />
                    </div>
                    <div class="grid grid-rows md:grid-cols-4 space-y-1">
                        <label class="label text-base label-text md:col-start-2">Frequency
                            <div class="tooltip tooltip-left md:tooltip-top"
                                data-tip="Will this amount be withdrawn every month or total of every year?"> 🛈</div>
                        </label>
                        <div>
                            <label>
                                <input type="radio" value="Yearly" v-model="withdrawal.frequency" />
                                Yearly
                            </label>
                            <label>
                                <input type="radio" value="Monthly" v-model="withdrawal.frequency" />
                                Monthly
                            </label>
                        </div>
                    </div>
                    <div class="grid grid-rows md:grid-cols-4 space-y-1">
                        <label class="label text-base label-text md:col-start-2">Change the amount every year by %
                            <div class="tooltip tooltip-left md:tooltip-top"
                                data-tip="Enter a positive rate value to increase withdrawal every year by that %, or a negative rate value to decrease it by that %. E.g. a positive value may be used to account for inflation, or a negative value may show reducing needs/dependencies.">
                                🛈</div>
                        </label>
                        <input type="number" step="0.01" placeholder="Ex: 8.5" min="0"
                            v-model.number="withdrawal.change_percentage" class="input input-bordered" required />
                    </div>
                    <div class="grid grid-rows md:grid-cols-4 space-y-1 range-slider">
                        <label class="label text-base label-text md:col-start-2">Year Range (Year 0 = This year)
                            <div class="tooltip tooltip-left md:tooltip-top"
                                data-tip="When do you expect this withdrawal to start/stop?">
                                🛈</div>
                        </label>
                        <Slider v-model="withdrawal.year_range" showTooltip="always" tooltipPosition="bottom"
                            class="max-sm:px-2" />
                    </div>
                </div>
                <div class="grid grid-rows md:grid-cols-4 mt-4 max-sm:mt-11">
                    <button type="button" @click="store.addWithdrawalSchedule"
                        class="btn btn-outline md:col-start-2">Add
                        Withdrawal Schedule</button>
                </div>
            </div>
        </div>
        <div class="collapse collapse-arrow bg-base-200 my-1">
            <input type="checkbox" name="accordion"
                title="Lumpsum withdrawals expected in future for meeting any big goals like education/marriage/medical etc" />
            <div class="collapse-title text-xl font-medium">
                Goals / Lumpsum Withdrawals
            </div>
            <div class="collapse-content">
                <div v-for="(goal, index) in store.inputAdvanced.goals" :key="index">
                    <div class="grid grid-rows md:grid-cols-4 space-y-1">
                        <label class="label text-base label-text md:col-start-2">Goal / Lumpsum Withdrawal {{ index + 1
                            }}
                        </label>
                        <a type="button" @click="store.deleteGoal(index)" class="btn btn-square">🗑️</a>
                        <label class="label text-base label-text md:col-start-2">Name
                            <div class="tooltip tooltip-left md:tooltip-top"
                                data-tip="Optional. Any name to identify your goal for which a lumpsum withdrawal will be made">
                                🛈</div>
                        </label>
                        <input v-model="goal.name" placeholder="Ex: Kid's College" class="input input-bordered" />
                    </div>
                    <div class="grid grid-rows md:grid-cols-4 space-y-1">
                        <label class="label text-base label-text md:col-start-2">Amount
                            <div class="tooltip tooltip-left md:tooltip-top" data-tip="Amount to withdraw"> 🛈</div>
                        </label>
                        <numbers placeholder="Ex: 1,00,000" v-model.formattedInputNumber="goal.amount"
                            class="input input-bordered" required />
                    </div>
                    <div class="grid grid-rows md:grid-cols-4 space-y-1">
                        <label class="label text-base label-text md:col-start-2">Inflation Rate %
                            <div class="tooltip tooltip-left md:tooltip-top"
                                data-tip="Inflation rate to take into account for scaling the goal amount for the year when it will be withdrawn. Leave at 0 if you have already accounted for it yourself.">
                                🛈</div>
                        </label>
                        <input type="number" step="0.01" placeholder="Ex: 8.5" min="0"
                            v-model.number="goal.inflation_rate" class="input input-bordered" required />
                    </div>
                    <div class="grid grid-rows md:grid-cols-4 space-y-1 range-slider items">
                        <label class="label text-base label-text md:col-start-2">Year (Year 0 = This year)
                            <div class="tooltip tooltip-left md:tooltip-top"
                                data-tip="Which year will this be withdrawn?">
                                🛈</div>
                        </label>
                        <Slider v-model="goal.year" showTooltip="always" tooltipPosition="bottom" class="max-sm:px-2" />
                    </div>
                </div>
                <div class="grid grid-rows md:grid-cols-4 mt-4 max-sm:mt-11">
                    <button type="button" @click="store.addGoal" class="btn btn-outline md:col-start-2">Add
                        Goal</button>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-2 md:grid-cols-4 m-2">
            <button type="submit"
                class="btn btn-primary btn-block md:col-start-2 col-span-2 plausible-event-name=Calculate+Advanced">
                Calculate
            </button>
        </div>
    </form>
</template>