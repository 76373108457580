<script setup>
import { reactive } from 'vue';
import { generateKey, encrypt, decrypt, sha256, getKeyString, retreiveKeyfromString } from '../crypto';
import { dataStore } from '../stores/data';
import axios from 'axios';

const store = dataStore();
const result = reactive({
    showable: false,
    linkEditable: "",
    linkShareable: "",
    message: "",
    outputHash: "",
});

function makeShareAddUrl() {
    let url = "/share";
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('id')) {
        if (urlParams.has('edit')) {
            url += "?id=" + urlParams.get('id') + "&edit=" + urlParams.get('edit');
        }
    }
    return url;
}

function getOrGenerateKey() {
    let urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('key')) {
        return retreiveKeyfromString(urlParams.get('key'));
    } else {
        return generateKey();
    }
}
async function share() {
    const key = await getOrGenerateKey();
    if (store.output.result.length != 0) {
        const hash = await sha256(JSON.stringify(store.output.result));
        if (String.fromCharCode.apply(null, new Uint8Array(hash)) != String.fromCharCode.apply(null, new Uint8Array(result.outputHash))) {
            result.outputHash = hash;
            const encrypted = await encrypt(key, JSON.stringify(store.inputAdvanced));
            const objectKey = await getKeyString(key);
            axios.post(makeShareAddUrl(), encrypted)
                .then(function (response) {
                    result.linkShareable = window.location.origin + "?id=" + response.data.id + "&key=" + objectKey;
                    result.linkEditable = result.linkShareable + "&edit=" + response.data.edit;
                    result.message = "Sharing Links Generated: "
                });
        } else {
            result.message = "No new calculations seem to be done since last share link generation!";
        }
    } else {
        result.message = "Please calculate a result first before generating a sharing link!";
    }
    result.showable = true;
}

// Add a variable from parent component to clear the state

function clear() {
    result.showable = false;
    result.linkEditable = "";
    result.linkShareable = "";
    result.message = "";
}

async function init() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('id')) {
        if (urlParams.has('key')) {
            let key = urlParams.get('key');
            let id = urlParams.get('id');
            axios.get('/share?id=' + id)
                .then(async function (response) {
                    store.inputAdvanced = JSON.parse(await decrypt(key, response.data));
                });
        }
        // Show error here that key is missing
    }
}

init();
</script>

<template>
    <div role="alert" class="alert alert-info" v-if="result.showable">
        <div class="tooltip tooltip-left md:tooltip-top" data-tip="Editable links can be used to make changes to the same model and they will be reflected
             to everyone using editable/viewable links. Any changes made to the data from a viewable link will
              not update original data and will result in a new link if shared again.
              All the data is encrypted in the browser and the backend never stores your data unencrypted. 
              The encryption key is not stored on the server as well.">
            🛈</div>
        <ul>
            <li>{{ result.message }}</li>
            <li v-if="result.linkEditable">Editable: <a class="link" :href=result.linkEditable>{{
        result.linkEditable }}</a>
            </li>
            <li v-if="result.linkShareable">Viewable: <a class="link" :href=result.linkShareable>{{
        result.linkShareable }}</a>
            </li>
        </ul>
    </div>
    <button type="button" @click="share" class="btn btn-outline m-1 plausible-event-name=share">Generate Sharing
        Link</button>
</template>