<script setup>
import { dataStore } from '../stores/data';
import { ref, watch } from 'vue';
import { Chart, LinearScale, CategoryScale, LineController, BarController, PointElement, LineElement, BarElement, Tooltip } from 'chart.js';

const store = dataStore();
// Register the required scales
Chart.register(LinearScale, CategoryScale, LineController, BarController, PointElement, LineElement, BarElement, Tooltip);

// Create a reference for the chart
const chartRef = ref(null);
let chartInstance = null;

// Watch for changes in store.output.result
watch(() => store.output.result, (newData) => {
    if (newData.length) {
        // Destroy the existing chart instance if it exists
        if (chartInstance) {
            chartInstance.destroy();
        }
        const chartData = newData.map((row) => ({
            year: row.year,
            balance: row.balance,
            netChange: row.net_change,
        }));

        // Create the chart
        chartInstance = new Chart(chartRef.value.getContext('2d'), {
            type: 'bar',
            data: {
                labels: chartData.map((data) => data.year),
                datasets: [
                    {
                        label: 'Balance',
                        yAxisID: 'balance-y-axis',
                        data: chartData.map((data) => data.balance),
                        type: 'line',
                        borderColor: 'blue',
                        backgroundColor: 'transparent',
                    },
                    {
                        label: 'Net Change',
                        yAxisID: 'net-change-y-axis',
                        data: chartData.map((data) => data.netChange),
                        type: 'bar',
                        backgroundColor: 'green',
                    },
                ],
            },
            options: {
                maintainAspectRatio: true,
                responsive: true,
                scales: {
                    x: {
                        beginAtZero: true,
                        offset: true,
                        grid: {
                            display: false,
                        },
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 10,
                            maxRotation: 0,
                            minRotation: 0,
                        },
                        title: {
                            display: true,
                            text: 'Year', // Set the desired label for the x-axis
                        },
                    },
                    y: {
                        beginAtZero: true,
                        display: false,
                    },
                    'balance-y-axis': {
                        position: 'left',
                        title: {
                            display: true,
                            text: 'Balance',
                        },
                    },
                    'net-change-y-axis': {
                        position: 'right',
                        title: {
                            display: true,
                            text: 'Net Change',
                        },
                    },
                },
                plugins: {
                    tooltip: {
                        enabled: true,
                        mode: 'nearest',
                        intersect: false,
                    }
                }
            },
        });
    }
});

</script>

<template>
    <canvas ref="chartRef"></canvas>
</template>